import React from "react"

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <section className="source">
          <h3><img src="/cull-sketch-w.png" alt="Cull" title="Cull" /></h3>
          <p><a className="name" href="https://cull.email">Cull</a> your email.</p>
          <p><a className="github" href="https://github.com/cull-email">Open Source</a></p>
        </section>
        <section className="author">
          <img src="/kuokoa.svg" alt="Kuokoa Studio" title="Kuokoa Studio" />
          <p>Made in Hawaiʻi</p>
          <p>&copy; <a href="https://kuokoa.studio">Kuokoa Studio</a></p>
        </section>
      </footer>
    )
  }
}

export default Footer
