import React from "react"
import { Link } from "gatsby"

class Header extends React.Component {
  state = {
    menuExpanded: false,
    menuToggleClicked: false,
    alpha: 0,
  }
  toggleMenuExpansion = () => {
    this.setState({
      menuToggleClicked: true,
      menuExpanded: !this.state.menuExpanded
    })
  }
  render() {
    const menuExpanded = this.state.menuExpanded
    const menuClassName = menuExpanded ? `expanded` : ``
    return (
      <header>
        <section className="primary">
          <Link to="/" title="Home">Cull</Link>
        </section>
      </header>
    )
  }
}

export default Header
