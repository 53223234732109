import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import "normalize.css"
import "../styles/index.scss"

class Layout extends React.Component {
  render() {
    const { children } = this.props
    return (
      <div>
        <Header />
        <main>{children}</main>
        <Footer />
      </div>
    )
  }
}

export default Layout
